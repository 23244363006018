import { ACTION_TYPES } from '@store/actionTypes'

// **  Initial State
const initialState = {
  clinicPatientId: '',
  properties: []
}

const propsOfPatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SAVE_PROPS_OF_PATIENT:
      if (action.data) {
        return {
          ...state,
          ...action.data
        }
      } else {
        return { ...state }
      }

    default:
      return state
  }
}

export default propsOfPatientReducer
